import styled from "styled-components";

export const Container = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
`;

export const WebsiteRights = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoWrap = styled.div``;

export const ImgWrap = styled.div`
  padding: 0 6px 0 0;
`;
