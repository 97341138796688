import Image from "next/image";
import Link from "next/link";
import { Container, WebsiteRights, LogoWrap, ImgWrap } from "./FooterElements";
import LogoWhite from "@/images/logo-white.svg";
import LogoBlack from "@/images/logo-black.svg";

export default function Footer(): JSX.Element {
  return (
    <>
      <Container id="footer" className="header">
        <WebsiteRights id="website-rights">
          <LogoWrap>
            <ImgWrap className="logo-white">
              <Link href="/" passHref>
                <Image
                  alt="Ask Marcus"
                  src={LogoWhite}
                  width="66"
                  height="66"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  priority
                />
              </Link>
            </ImgWrap>
            <ImgWrap className="logo-black">
              <Link href="/" passHref>
                <Image
                  alt="Ask Marcus"
                  src={LogoBlack}
                  width="66"
                  height="66"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  priority
                />
              </Link>
            </ImgWrap>
          </LogoWrap>
          © {new Date().getFullYear()}
        </WebsiteRights>
      </Container>
    </>
  );
}
