import { Content } from "./LayoutElements";
import Footer from "@/components/Footer";

export default function Layout({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <>
      <Content id="content">
        {children}
        <Footer />
      </Content>
    </>
  );
}
