import type { AppProps } from "next/app";
import { useEffect } from "react";
import Script from "next/script";
import { Router, useRouter } from "next/router";
import { Inter } from "next/font/google";
import { logEvent } from "firebase/analytics";
// import { Analytics } from "@vercel/analytics/react";
import { motion } from "framer-motion";
import { createGlobalStyle, keyframes } from "styled-components";
import { ThemeProvider } from "next-themes";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { analytics } from "@/config/firebase";
import Layout from "@/components/Layout";

const inter = Inter({
  subsets: ["latin"],
  variable: "--primary-font",
});

// https://developer.apple.com/design/human-interface-guidelines/foundations/color/
const themes = {
  lightMode: {
    systemBlack: "rgb(0, 0, 0)",
    systemBlackTwo: "rgb(12, 12, 12)",
    systemBlue: "rgb(0, 122, 255)",
    systemBlueTwo: "rgb(0, 11, 24)",
    systemBrown: "rgb(162, 132, 94)",
    systemBrownTwo: "rgb(15, 12, 9)",
    systemCyan: "rgb(50, 173, 230)",
    systemCyanTwo: "rgb(3, 15, 21)",
    systemGray: "rgb(142, 142, 147)",
    systemGrayTwo: "rgb(174, 174, 178)",
    systemGreen: "rgb(52, 199, 89)",
    systemGreenTwo: "rgb(5, 19, 9)",
    systemIndigo: "rgb(175, 82, 222)",
    systemIndigoTwo: "rgb(15, 4, 20)",
    systemMint: "rgb(0, 199, 190)",
    systemMintTwo: "rgb(0, 24, 23)",
    systemOrange: "rgb(255, 149, 0)",
    systemOrangeTwo: "rgb(24, 14, 0)",
    systemPink: "rgb(255, 45, 85)",
    systemPinkTwo: "rgb(24, 0, 4)",
    systemPurple: "rgb(175, 82, 222)",
    systemPurpleTwo: "rgb(15, 4, 20)",
    systemRed: "rgb(255, 59, 48)",
    systemRedTwo: "rgb(24, 1, 0)",
    systemTeal: "rgb(48, 176, 199)",
    systemTealTwo: "rgb(5, 17, 19)",
    systemWhite: "rgb(255, 255, 255)",
    systemYellow: "rgb(255, 204, 0)",
    systemYellowTwo: "rgb(24, 19, 0)",
  },
  darkMode: {
    systemBlack: "rgb(0, 0, 0)",
    systemBlue: "rgb(10, 132, 255)",
    systemBlueTwo: "rgb(0, 12, 24)",
    systemBrown: "rgb(172, 142, 104)",
    systemBrownTwo: "rgb(15, 12, 9)",
    systemCyan: "rgb(100, 210, 255)",
    systemCyanTwo: "rgb(0, 17, 24)",
    systemGray: "rgb(142, 142, 147)",
    systemGreen: "rgb(48, 209, 88)",
    systemGreenTwo: "rgb(4, 20, 8)",
    systemIndigo: "rgb(94, 92, 230)",
    systemIndigoTwo: "rgb(4, 3, 21)",
    systemMint: "rgb(99, 230, 226)",
    systemMintTwo: "rgb(3, 21, 20)",
    systemOrange: "rgb(255, 159, 10)",
    systemOrangeTwo: "rgb(24, 15, 0)",
    systemPink: "rgb(255, 55, 95)",
    systemPinkTwo: "rgb(24, 0, 5)",
    systemPurple: "rgb(191, 90, 242)",
    systemPurpleTwo: "rgb(15, 2, 22)",
    systemRed: "rgb(255, 59, 48)",
    systemRedTwo: "rgb(24, 1, 0)",
    systemTeal: "rgb(64, 200, 224)",
    systemTealTwo: "rgb(3, 18, 21)",
    systemWhite: "rgb(255, 255, 255)",
    systemWhiteTwo: "rgb(244, 244, 244)",
    systemYellow: "rgb(255, 214, 10)",
    systemYellowTwo: "rgb(24, 20, 0)",
  },
};

const dotLoaderAnimation = keyframes`
  {
    to {
      clip-path: inset(0 -34% 0 0)
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  //Boilerplate
  html {
    height: 100%;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--primary-font);
    font-size: 1rem;
    min-height: 100%;
    line-height: 1.5;
    scroll-behavior: smooth;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  h1 {
    font-weight: 500;
  }

  //Global
  ::selection {
    background: ${() => themes.lightMode.systemBlackTwo};
    color: ${() => themes.lightMode.systemWhite};
  }
  
  ::-moz-selection {
    background: ${() => themes.lightMode.systemBlackTwo};
    color: ${() => themes.lightMode.systemWhite};
  }

  input[type="text"], textarea, .input {
    background-color: transparent;
  }

  textarea {
    font-family: var(--primary-font);
  }

  a:link {
    text-decoration: none;
    color: inherit;
  }

  a:visited {
    text-decoration: none;
    color: inherit;
  }

  a:hover {
    text-decoration: none;
    color: inherit;
  }

  a:active {
    text-decoration: none;
    color: inherit;
  }

  .link {
    font-size: 1.25rem;
    color: inherit;
    text-decoration: none;
  }

  .input-disabled {
    opacity: 0.25;
  }

  .button-disabled {
    &:hover {
      background-color: unset !important;
      color: unset !important;
    }
  }

  .tooltip {
    width: 350px;
    margin: 0 auto;
    padding: 16px 24px;
    font-size: 1rem;
    border-radius: 0.375rem;
    opacity: 1;
    z-index: 1;

    @media screen and (max-width: 960px) {
      width: 300px;
    }

    @media screen and (max-width: 480px) {
      width: 200px;
    }
  }

  .rotate {
    transform: rotate(-45deg);
  }

  .flex-end {
    align-self: flex-end;
  }
  
  .dot-loader {
    background-size: calc(100%/3) 12px;
    background-repeat: no-repeat;
    animation: ${dotLoaderAnimation} 1s steps(4) infinite;
  }

  //Light-mode theme
  :root {
    color: ${() => themes.lightMode.systemBlack};
    background-color: ${() => themes.lightMode.systemWhite};

    #contact-form .input {
      border: 1px solid ${() => themes.lightMode.systemOrange};
    }

    .contact-button {
      border: 1px solid ${() => themes.lightMode.systemOrange};

      &:hover {
        background-color: ${() => themes.lightMode.systemOrange};
        color: ${() => themes.lightMode.systemOrangeTwo};
      }
    }

    .input:focus, textarea:focus {
      outline: none !important;
      border:1px solid ${() => themes.lightMode.systemOrange};
      box-shadow: 0 0 10px ${() => themes.lightMode.systemOrange};
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px ${() =>
        themes.lightMode.systemOrange} inset !important;
      -webkit-text-fill-color: ${() =>
        themes.lightMode.systemOrangeTwo} !important;
    }

    .textarea {
      background-color: ${() => themes.lightMode.systemWhite};
    }

    .error {
      border: solid 1px ${() => themes.lightMode.systemRed};
    }

    .error-message {
      color: ${() => themes.lightMode.systemRed};
    }

    .logo-white {
      display: none;
    }

    .logo-black{
      display: block;
    }

    .home-link{
      color: ${() => themes.lightMode.systemPurple} !important;
      text-decoration: none;
    }

    .home-link:systemBlackTwo {
      color:  !important;
    }

    .bg-blue {
      background-color: ${() => themes.lightMode.systemBlue};
    }

    .bg-gray {
      background-color: ${() => themes.lightMode.systemGray};
    }

    .header, .form {
      background-color: ${() => themes.lightMode.systemWhite};
    }

    .hover-orange {
      &:hover {
        background-color: ${() => themes.darkMode.systemOrange};
      }
    }

    .dot-loader {
      background: 
        radial-gradient(circle closest-side,${() =>
          themes.lightMode.systemGray} 90%,#0000) 0%   50%,
        radial-gradient(circle closest-side,${() =>
          themes.lightMode.systemGray} 90%,#0000) 50%  50%,
        radial-gradient(circle closest-side,${() =>
          themes.lightMode.systemGray} 90%,#0000) 100% 50%;
      background-size:calc(100%/3) 12px;
      background-repeat: no-repeat;
    }
  }

  //Dark-mode theme
  [data-theme="dark"] {
    color: ${() => themes.darkMode.systemWhite};
    background-color: ${() => themes.darkMode.systemBlack};

    div::selection, h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection, p::selection, a::selection, button::selection, ul::selection, li::selection, span::selection, input::selection, textarea::selection, small::selection, img::selection, video::selection, br::selection, code::selection, iframe::selection {
      background: ${() => themes.darkMode.systemWhite};
      color: ${() => themes.darkMode.systemBlack};
    }

    div::-moz-selection, h1::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, h5::-moz-selection, h6::-moz-selection, p::-moz-selection, a::-moz-selection, button::-moz-selection, ul::-moz-selection, li::-moz-selection, span::-moz-selection, input::-moz-selection, textarea::-moz-selection, small::-moz-selection, img::-moz-selection, video::-moz-selection, br::-moz-selection, code::-moz-selection, iframe::-moz-selection {
      background: ${() => themes.darkMode.systemWhite};
      color: ${() => themes.darkMode.systemBlack};
    }

    .primary-button, .input {
      border: 1px solid ${() => themes.darkMode.systemWhite};
      fill: ${() => themes.darkMode.systemWhite};
    }

    #contact-form .input {
      border: 1px solid ${() => themes.darkMode.systemOrange};
    }

    .contact-button {
      border: 1px solid ${() => themes.darkMode.systemOrange};

      &:hover {
        background-color: ${() => themes.darkMode.systemOrange};
        color: ${() => themes.darkMode.systemOrangeTwo};
      }
    }

    .input:focus, textarea:focus  {
      outline: none !important;
      border:1px solid ${() => themes.darkMode.systemOrange};
      box-shadow: 0 0 10px ${() => themes.darkMode.systemOrange};
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px ${() =>
        themes.darkMode.systemOrange} inset !important;
      -webkit-text-fill-color: ${() =>
        themes.darkMode.systemOrangeTwo} !important;
    }

    .textarea {
      background-color: ${() => themes.darkMode.systemBlack};
    }

    .error {
      border: solid 1px ${() => themes.darkMode.systemRed};
    }

    .error-message {
      color: ${() => themes.darkMode.systemRed};
    }

    .logo-white{
      display: block;
    }

    .logo-black {
      display: none;
    }

    .home-link{
      color: ${() => themes.darkMode.systemPurple} !important;
      text-decoration: none;
    }

    .home-link:systemBlackTwo {
      color: ${() => themes.darkMode.systemPurple} !important;
    }

    .bg-blue {
      background-color: ${() => themes.darkMode.systemBlue};
    }

    .bg-gray {
      background-color: ${() => themes.darkMode.systemGray};
    }

    .header, .form {
      background-color: ${() => themes.darkMode.systemBlack};
    }

    .hover-orange {
      &:hover {
        background-color: ${() => themes.darkMode.systemOrange};
      }
    }

    .dot-loader {
      background: 
        radial-gradient(circle closest-side,${() =>
          themes.lightMode.systemGray} 90%,#0000) 0%   50%,
        radial-gradient(circle closest-side,${() =>
          themes.lightMode.systemGray} 90%,#0000) 50%  50%,
        radial-gradient(circle closest-side,${() =>
          themes.lightMode.systemGray} 90%,#0000) 100% 50%;
      background-size:calc(100%/3) 12px;
      background-repeat: no-repeat;
    }
  }
`;

export default function App({ Component, pageProps }: AppProps) {
  Router.events.on("routeChangeStart", () => NProgress.start());
  Router.events.on("routeChangeComplete", () => NProgress.done());
  Router.events.on("routeChangeError", () => NProgress.done());

  const router = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logFirebaseEvent = (url: string) => {
        logEvent(analytics, "screen_view", {
          firebase_screen: url,
          firebase_screen_class: url,
        });
      };

      router.events.on("routeChangeComplete", logFirebaseEvent);

      return () => {
        router.events.off("routeChangeComplete", logFirebaseEvent);
      };
    }
  }, [router.events]);

  return (
    <>
      <GlobalStyle />
      <Script
        id="microsoft-clarity"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_API_KEY}");
         `,
        }}
      />
      <ThemeProvider>
        <motion.div
          className={inter.className}
          key={router.route}
          initial="pageInitial"
          animate="pageAnimate"
          variants={{
            pageInitial: {
              opacity: 0,
            },
            pageAnimate: {
              opacity: 1,
            },
          }}
        >
          <Layout>
            <Component {...pageProps} />
            {/* <Analytics /> */}
          </Layout>
        </motion.div>
      </ThemeProvider>
    </>
  );
}
