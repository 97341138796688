import styled from "styled-components";

export const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-width: calc(100% - 1vw);
  margin: 0 auto;

  @media screen and (min-width: 2056px) {
    max-width: calc(100% - 50vw);
  }
`;
